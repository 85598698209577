import React from 'react';
import Root from './components/Root';
import Header from './components/Header';
import Body from './components/Body';
import { useNavigate } from 'react-router-dom';
import Button from './components/Button';
import { Box, Grid } from '@mui/material';
import { TranslateInterface } from '../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../utils/hooks/useLanguage';

type Props = {};

const ChoicePageLayout = (props: Props) => {
  const { translate }: TranslateInterface = useLanguage();

  const navigate = useNavigate();

  return (
    <Root>
      <Header />

      <Body>
        <Grid
          container
          spacing={{
            xl: 2,
            lg: 2,
            md: 1,
            sm: 0,
            xs: 0
          }}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button onClick={() => navigate('/comparison')}>
              {translate('page.choice.options.comparison')}
            </Button>

            <Button onClick={() => navigate('/reviewer')}>
              {translate('page.choice.options.textReviewer')}
            </Button>

            <Button onClick={() => navigate('/re-writer')}>
              {translate('page.choice.options.rewriteGenerator')}
            </Button>

            <Button onClick={() => navigate('/email-marketing')}>
              {translate('page.choice.options.emailMarketing')}
            </Button>

            <Button onClick={() => navigate('/post-blog')}>
              {translate('page.choice.options.postBlog')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button onClick={() => navigate('/summarize-text')}>
              {translate('page.choice.options.summarizeText')}
            </Button>

            <Button onClick={() => navigate('/hashtag-generator')}>
              {translate('page.choice.options.hashtagGenerator')}
            </Button>

            <Button onClick={() => navigate('/commemorative-date')}>
              {translate('page.choice.options.commemorativeDate')}
            </Button>

            <Button onClick={() => navigate('/post-instagram')}>
              {translate('page.choice.options.postInstagram')}
            </Button>

            <Button onClick={() => navigate('/text-generator')}>
              {translate('page.choice.options.textGenerator')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button onClick={() => navigate('/slogan')}>
              {translate('page.choice.options.slogan')}
            </Button>

            <Button onClick={() => navigate('/aida-structure')}>
              {translate('page.choice.options.aidaStructure')}
            </Button>

            <Button onClick={() => navigate('/content-improver')}>
              {translate('page.choice.options.contentImprover')}
            </Button>

            <Button onClick={() => navigate('/post-linkedin')}>
              {translate('page.choice.options.postLinkedin')}
            </Button>

            <Button onClick={() => navigate('/humanize-text')}>
              {translate('page.choice.options.humanizeText')}
            </Button>
          </Grid>
        </Grid>
      </Body>
    </Root>
  );
};

export default ChoicePageLayout;
