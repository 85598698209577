/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../utils/models/dispatchTypes';
import GenerativeRequest from '../requests/generative';
import { decreaseLoading, increaseLoading } from './loading';
import RequestErrorHelper from '../../utils/helpers/RequestErrorHelper';
import validate from '../../utils/functions/validate';
import notify from '../../utils/notify';

export const generative =
  (message: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await GenerativeRequest.generative(
        message,
        language
      );

      if (cb && cb.success) {
        cb.success(
          payload.output.map((item: any) => ({ ...item, id: item._id }))
        );
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateChosen =
  (id: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await GenerativeRequest.chosen(
        id,
        language
      );

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const rewriter =
  (message: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_REWRITER');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        // notify.error('Você atingiu o limite de uso diário!');

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        // notify.error(
        //   'Você tem que esperar 24 horas para usar a ferramenta novamente!'
        // );

        return;
      }

      const payload: models.ChatResponse = await GenerativeRequest.rewriter(
        message,
        language
      );

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const hashtagGenerator =
  (message: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_HASHTAG_GENERATOR');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        // notify.error('Você atingiu o limite de uso diário!');

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        // notify.error(
        //   'Você tem que esperar 24 horas para usar a ferramenta novamente!'
        // );

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.hashtagGenerator(message, language);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const summarizeText =
  (message: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_SUMMARIZE_TEXT');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.summarizeText(message, language);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const commemorativeDate =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_COMMEMORATIVE_DATE');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.commemorativeDate(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const slogan =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_SLOGAN');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse = await GenerativeRequest.slogan(
        params
      );

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const aidaStructure =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_AIDA_STRUCTURE');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.aidaStructure(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const contentImprover =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_CONTENT_IMPROVER');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.contentImprover(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const emailMarketing =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_EMAIL_MARKETING');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.emailMarketing(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const postInstagram =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_POST_INSTAGRAM');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.postInstagram(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const postLinkedin =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_POST_LINKEDIN');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse = await GenerativeRequest.postLinkedin(
        params
      );

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const postBlog =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_POST_BLOG');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse = await GenerativeRequest.postBlog(
        params
      );

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const textGenerator =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_TEXT_GENERATOR');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse =
        await GenerativeRequest.textGenerator(params);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const humanizeText =
  (params: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const response = await validate('TOOL_HUMANIZE_TEXT');

      if (response === 'LIMIT_REACHED') {
        cb.error(response);

        return;
      }

      if (response === 'TIME_REACHED') {
        cb.error(response);

        return;
      }

      const payload: models.ChatResponse = await GenerativeRequest.humanizeText(
        params
      );

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };
