import React from 'react';
import Root from './components/Root';
import Body from './components/Body';
import TextAreaContainer from './components/TextAreaContainer';
import Loading from './components/Loading';
import useForm from './hooks/useForm';
import Forms from './components/Forms';
import { useAppDispatch } from '../../../../utils/hooks/useAppDispatch';
import ModalPremium from '../../components/Modal/Premium';

type Props = {};

const HumanizeTextPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    modal,
    form,
    output,
    loading,
    handleChange,
    handleSubmit,
    handleReset
  } = useForm(dispatch);

  return (
    <Root>
      <Body>
        <ModalPremium
          open={modal.open}
          toggle={modal.toggle}
          type={modal.type}
        />

        <TextAreaContainer>
          <Forms
            form={form}
            output={output}
            loading={loading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
          />
        </TextAreaContainer>
      </Body>
    </Root>
  );
};

export default HumanizeTextPage;
